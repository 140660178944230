(function ($) {

    var courseContent = $('.course-content');
    var videoLinks = courseContent.find('.video-link');

    if (videoLinks.length === 0) {
        return;
    }

    videoLinks.magnificPopup({
        type: 'iframe',
    });

})(jQuery);