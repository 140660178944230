(function ($) {

    var courseRegForm = $('.course-registration-form');
    var dateFields = courseRegForm.find('.date-field');

    if (courseRegForm.length === 0) {
        return;
    }

    dateFields.each(function () {

        $(this).datepicker({
            format: 'dd/mm/yyyy',
        });

    });

})(jQuery);