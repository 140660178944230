(function ($) {

    var excludeClass = '.video-link';
    var courseContentSections = $('.course-content__sections');
    var mainSections = courseContentSections.find('.course-content__section').not(excludeClass);
    var plusIcon = '<svg class="plus" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M17 13h-4v4h-2v-4H7v-2h4V7h2v4h4m-5-9A10 10 0 0 0 2 12a10 10 0 0 0 10 10 10 10 0 0 0 10-10A10 10 0 0 0 12 2Z"/></svg>';
    var minusIcon = '<svg class="minus hidden" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M17 13H7v-2h10m-5-9A10 10 0 0 0 2 12a10 10 0 0 0 10 10 10 10 0 0 0 10-10A10 10 0 0 0 12 2Z"/></svg>';

    if (mainSections.length === 0) {
        return;
    }

    $(function () {

        mainSections.each(function () {
            // Elements belonging to each main section
            var mainIconWrapper = $(this).find('.icon');
            var mainHiddenClass = 'course-content__section--collapsed';
            var mainActiveClass = 'course-content__section--active';
            var subSections = $(this).next('.subsection-wrapper');
            var subsectionHiddenClass = 'course-content__subsection--collapsed';
            var subSectionActiveClass = 'course-content__subsection--active';

            // Append icons
            mainIconWrapper.append(plusIcon).append(minusIcon);

            // Hide subsections on page load
            $(this).addClass(mainHiddenClass);

            // Toggle subsections on click
            $(this).click(function (e) {
                e.preventDefault();
                $(this).toggleClass(mainHiddenClass).toggleClass(mainActiveClass);
                mainIconWrapper.find('svg').toggleClass('hidden');
            });

            // Subsection functionality
            subSections.each(function () {
                var actualSubsection = $(this).find('.course-content__subsection').not(excludeClass);

                actualSubsection.each(function () {

                    var subsectionIconWrapper = $(this).find('.icon');

                    // Append icons
                    subsectionIconWrapper.append(plusIcon).append(minusIcon);

                    // Hide subsection content on page load
                    $(this).addClass(subsectionHiddenClass);

                    // Toggle subsection content on click
                    $(this).click(function (e) {
                        e.preventDefault();
                        $(this).toggleClass(subsectionHiddenClass).toggleClass(subSectionActiveClass);
                        subsectionIconWrapper.find('svg').toggleClass('hidden');
                    });
                });
            });
        });

    });

})(jQuery);